import './Error_msg.css';

function ErrorPage() {
    return (
        <div className="error_container">
            <h1>Oops 🙈 This page does not exist 👩🏼‍💻</h1>
        </div>
    )
}
 
export default ErrorPage